export const useHousing = () => {
    return [
        { id: 1, name: 'villa', icon: 'PhHouse', text: "Villa" },
        { id: 2, name: 'condominium', icon: 'PhHouse', text: "Ejerlejlighed" },
        { id: 3, name: 'country property', icon: 'PhHouse', text: "Landejendom" },
        { id: 4, name: 'housing cooperative', icon: 'PhHouse', text: "Andelsbolig" },
        { id: 5, name: 'cottage', icon: 'PhHouse', text: "Sommerhus" },
        { id: 6, name: 'land', icon: 'PhHouse', text: "Grund" }
    ]
}
  