<script setup lang="ts">
import { transformMany } from '~/utils/transform'
import type { City } from "~/types/city";

const { find } = useStrapi()

const cities:any = ref([])

function selectItem(item:City) {
  navigateTo(window.location.href = '/search?cities=' + item.id)
}

const fetchData = async () => {
  const { data: residence } = await useAsyncData(() => find<City>('cities', {
    filters: {
        showOnFrontPage: {
          $eq: true
        }
    },
    sort: 'name',
    pagination: {
        start: 0,
        limit: 10
    }
  }), { transform: transformMany<City> })

  cities.value = residence.value?.data
}

fetchData()
</script>

<template>
  
  <div class="p-4 lg:py-40 bg-greenish-500" style="background-color: #98B292;">
    <CategoryBox /> 

    <div class="flex items-center justify-center flex-col">
      
      <InputSearch class="w-full md:w-[600px] p-2 mb-2" @itemSelected="selectItem" />
      
      <div class="flex">
        <div class="max-w-3xl text-center flex flex-wrap gap-1">
          <NuxtLink v-for="(city, index) in cities" :key="index" :to="'/search?cities=' + city.id" class="
            px-2
            py-1
            text-sm
            text-secondary-50
            hover:text-secondary-100
            hover:underline
            drop-shadow 
            rounded-md
            ">
            {{ city.name }}
          </NuxtLink>
        </div>
      </div>

    </div>
  </div>
  
</template>
