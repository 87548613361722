<script setup lang="ts">
</script>

<template>
  <div>
    <TopBar />
    <slot />

    <footer class="relative pt-8 pb-6 bg-secondary-50 border-t border-black-50">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap text-left lg:text-left">
          <div class="w-full lg:w-6/12 px-4">
            <h4 class="text-xl font-semibold">Boligkartoteket</h4>
            <h5 class="text-md mt-0 mb-2">
              Find os på en af disse platforme.
            </h5>
            <div class="mt-4 lg:mb-0 mb-6 flex">
              <a href="https://facebook.com" target="_blank" class="flex bg-white-10 text-white-60 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2">
                <PhMetaLogo size="30" color="black" />
              </a>
              <a href="https://instagram.com" target="_blank" class="flex bg-white-10 text-white-60 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2">
                <PhInstagramLogo size="20" color="black" />
              </a>
              <a href="https://x.com" target="_blank" class="flex bg-white-10 text-white-60 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2">
                <PhTwitterLogo size="20" color="black" />
              </a>
            </div>
          </div>
          <div class="w-full lg:w-6/12">
            <div class="flex flex-wrap items-top mb-6">
              <div class="w-full lg:w-4/12 px-4 ml-auto">
                <span class="block uppercase text-sm font-semibold mb-2">Nyttige links</span>
                <ul class="list-unstyled">
                  <li>
                    <NuxtLink to="/about" class="hover:text-primary-80 block pb-2 text-sm">Om Boligkartoteket</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink to="/faq" class="hover:text-primary-80 block pb-2 text-sm">FAQ</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink to="/contact" class="hover:text-primary-80 block pb-2 text-sm">Kontakt</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink to="/help" class="hover:text-primary-80 block pb-2 text-sm">Hjælp</NuxtLink>
                  </li>
                </ul>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <span class="block uppercase text-sm font-semibold mb-2">Andre ressourcer</span>
                <ul class="list-unstyled">
                  <li>
                    <NuxtLink to="/privacy" class="hover:text-primary-80 block pb-2 text-sm">Persondatapolitik</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink to="/cookies" class="hover:text-primary-80 block pb-2 text-sm">Cookies</NuxtLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-6 border-white-30">
        <div class="flex flex-wrap items-center md:justify-between justify-center">
          <div class="w-full md:w-4/12 px-4 mx-auto text-center">
            <div class="text-xs font-semibold py-1">
              Copyright © <span id="get-current-year">2024</span> <a href="/about" class="hover:text-primary-80">Boligkartoteket</a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>