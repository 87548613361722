<script setup lang="ts">
// Imports
import { transformMany } from '~/utils/transform'
import type { City } from "~/types/city";
const { find } = useStrapi()

// Component props and emits
const props = defineProps({
  selectedItem: {
    type: Object,
    required: false
  }
});
const emit = defineEmits(['itemSelected'])

// Component state
const wrapper:any = ref(null)
const items:any = ref([])
const searchText = ref('')
const selectedItem = ref({})
const showDropdown = ref(false)
const isInputFocused = ref(false)
const currentIndex = ref(-1)

// Used to delay fetching data while user is typing
let delayTimer:any = null

// Fetch job offers matching the search text
const fetchData = async () => {
  const { data: residence } = await useAsyncData(() => find<City>('cities', {
    filters: {
        'name': {
          $containsi: searchText.value
        }
    },
    sort: 'name',
    pagination: {
        start: 0,
        limit: 5
    }
  }), { transform: transformMany<City> })

  items.value = residence.value?.data
  currentIndex.value = 0
}

// Event handlers
const selectItem = (item: any) => {
  searchText.value = item.name
  selectedItem.value = item
  showDropdown.value = false
  
  emit('itemSelected', item)

  // Reset the index
  currentIndex.value = -1
}

const closeDropdown = () => {
  showDropdown.value = false
}

const openDropdown = () => {
  showDropdown.value = true

  isInputFocused.value = true
  selectedItem.value = {}
}

const handleClickOutside = (event:any) => {
  if (wrapper.value && !wrapper.value.contains(event.target)) {
    closeDropdown()
  }
}

const handleSearchInput = (event:any) => {
  clearTimeout(delayTimer)
  delayTimer = setTimeout(fetchData, 250)
}

const handleKeyDown = (event:any) => {
  if (event.key === 'ArrowUp' && currentIndex.value > 0) {
    currentIndex.value--
  } else if (event.key === 'ArrowDown' && currentIndex.value < items.value.length - 1) {
    currentIndex.value++
  } else if (event.key === 'Enter' && currentIndex.value >= 0) {
    selectItem(items.value[currentIndex.value])
  }
}

const handleBlur = () => {
  isInputFocused.value = false;
  if (searchText.value === '') {
    
    // Hmmmmm:
    //closeDropdown()
    selectedItem.value = props.selectedItem || {};
  }
};

// Lifecycle hooks
onMounted(() => {
  fetchData()
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<template>

  <div class="relative" ref="wrapper">
    <div v-if="props.selectedItem && searchText.length === 0" class="absolute p-2 pl-12 flex items-center pointer-events-none">
      <div class="text-base-80 capitalize whitespace-nowrap">
        {{ props.selectedItem.name }}
      </div>
    </div>

    <label for="search" class="absolute p-2.5 pl-3">
      <PhMagnifyingGlass :size="20" />
    </label>
    <input id="search" type="text" autocomplete="off"
      :placeholder="props.selectedItem ? '' : 'Søg efter din nye bolig...'"
      v-model="searchText" 
      @input="handleSearchInput"
      @focus="openDropdown" 
      @blur="handleBlur"
      @keydown="handleKeyDown"
      class="
        border-0
        focus:ring-transparent
        rounded-xl 
        p-2 
        pl-10 
        placeholder-greenish-950
        w-full" 
        :class="{ 'bg-base-20': showDropdown, 'bg-white': !showDropdown }" />
        
      <transition 
        enter-active-class="transition ease-out duration-100"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-to-class="transform opacity-0 scale-95">
        <div v-if="showDropdown && items && items.length > 0" class="absolute bg-secondary-10 shadow-md shadow-lg rounded-b-lg -mt-2.5 z-50 left-2 right-2">
          <div v-for="(item, index) in items" :key="index"
            class="flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-greenish-200"
            :class="{ 'bg-base-20': currentIndex === index }"
            @click="selectItem(item)">
            <div class="text-base-80 truncate max-w-[50ch] capitalize whitespace-nowrap">
              {{ item.name }}
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>